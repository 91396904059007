.leadership_controller {
    .member {
        >ul {
            margin: 80px 0;

            > div{
                >li {
                    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
                    border-radius: 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 15px 0px;
                    border: 1px solid #ccc;
                    cursor: pointer;
                    padding: 20px;
                    margin-bottom: 30px;
    
                    >div {
                        background: #333;
                        overflow: hidden;
                        margin-bottom: 25px;
                        border-radius: 20px;
    
                        img {
                            border-radius: 20px;
                            transition: .5s all ease;
                            width: 100%;
                            object-fit: contain;
    
                            &:hover {
                                transform: scale(1.2);
                                opacity: .5;
                            }
                        }
                    }
    
                    h4 {
                        font-size: 1.7rem;
                        font-weight: 600;
                    }
    
                    ul {
                        li {
                            list-style: disc;
                            font-size: 1.6rem;
    
                        }
                    }
                }
            }
        }
    }
}

.MemberContent {
    padding-bottom: 40px;

    p {
        line-height: 35px;
    }

    b {
        font-size: 16px;
    }

    li {
        list-style: disc;
        font-size: 1.6rem;
        margin-left: 20px;
    }
}