$primarycolor: rgb(77, 183, 72);
$primarycolorHover: rgb(133, 223, 128);
$secondcolor: rgb(12, 76, 163);
$secondcolorHover: rgb(101, 160, 243);
$maxScreen: 1300px;
$tabletScreen: 992px;
$tabletSmallScreen: 768px;
$mobileScreen: 576px;
$heightMenu: 85px;



* {
    box-sizing: border-box;
    font-size: 62.5%;

}

body {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
}

li,
ul,
h1,
h2,
p {
    list-style: none;
    padding: 0;
    margin: 0;
}

a {
    margin: 0;
    padding: 0;
    text-decoration: none;
}

a:hover {
    color: $primarycolor;
}

.none {
    display: none !important;
}

.flex {
    display: flex !important;
}

.border-none {
    border: none !important;
}

.black-text {
    color: #333;
}

.background-sliver {
    background: #f3f3f3;
}

.p-bt-20 {
    padding: 20px 0;
}

.white-text {
    color: #fff;
}

.black-bg {
    background: black;
}

.white-bg {
    background: white;
}

input {
    height: 30px;
    border: 1px solid #ccc;
}

input:hover {
    outline: none;
    border-color: $primarycolor;
}

input:focus {
    outline: none;
    border-color: $primarycolor;
}

img {
    object-fit: contain;
}

p,
a,
span,
b,
label {
    font-size: 1.6rem;
}

.swiper {
    padding-bottom: 40px;
}

.category {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 40px 0 20px 0;
    flex-wrap: wrap;

    &-active {
        background: rgb(77, 183, 72);
        color: #fff;
        border: 1px solid #fff;
    }

    li {
        font-size: 1.6rem;
        padding: 5px 15px;
        border: 1px solid #ccc;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        border-radius: 50px;
        cursor: pointer;
        transition: 0.5s all ease;
        position: relative;
        margin-bottom: 10px;

        &:hover {
            background: rgb(77, 183, 72);
            color: #fff;
            border: 1px solid #fff;
        }

    }

    .categori_border::after {
        content: "";
        position: absolute;
        height: 3px;
        width: 150%;
        border-radius: 5px;
        bottom: -23px;
        background: rgb(203 249 201);
    }
}

.content_pages {
    margin-bottom: 40px;

    img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        width: auto;
        min-height: 300px;
        max-height: 500px;
    }

    p {
        font-size: 1.6rem;

        >img {
            max-height: 100%;
        }
    }

    b,
    strong {
        font-weight: 600;
        font-size: 1.6rem;

    }

    ul {
        padding-left: 44px;
        font-size: 15px;

    }

    li {
        font-size: 1.6rem;
        list-style: disc;
    }

    .tab-content {
        padding: 40px;
        border: 1px solid #ccc;
        border-radius: 20px;
    }

}

@mixin line-clam($number) {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: $number;
    -webkit-box-orient: vertical;
}

.background-img,
img {
    background-size: cover !important;
    overflow: hidden !important;
    // background-position: center;
    background-repeat: no-repeat !important;
    background-size: cover;
}


@media(max-width:1200px) {

    .header__menu-icon {
        display: none;
    }
}

@media(min-width:$maxScreen) {
    // .layout_container {
    //     max-width: 1300px;
    // }
}


@media(max-width:$maxScreen) {}

@media(max-width:1200px) {

    .header__menu-icon {
        display: none;
    }

    .banner-item>div {
        padding: 350px;

        p {
            font-size: 1.3rem;
        }
    }

    .section1 {
        .section1-right {
            padding: 0 !important;
        }

        div {
            p:first-child {
                font-size: 2.6rem;
            }

            p:last-child {
                font-size: 1.3rem !important;
            }

            h4 {
                font-size: 2.8rem !important;
            }



        }
    }

    .home .section1 .section1-model div p {
        font-size: 1.4rem;

    }
}

@media(max-width:$tabletScreen) {

    .TypicalProjectSlider-content p {
        font-size: 1.5rem !important;
    }

    .top_banner-title h4 {
        font-size: 2.5rem !important;
    }

    .footer_bottom-menu {
        display: none !important;
    }

    .banner-item>div {
        padding: 200px;

        p {
            font-size: 1.3rem;
        }

        h2 {
            font-size: 7rem;
        }
    }

}


@media(max-width:$tabletSmallScreen) {
    .banner-item>div {
        h2 {
            font-size: 6rem;
        }
    }

    .section1 {
        display: flex !important;
        align-items: center;

        &-model {
            padding: 0;
        }

        &-left {
            display: none;
        }
    }

    .home_ourservices-content>ul {
        justify-content: center;
    }

    .home .section3>div {
        display: flex !important;

        >div {
            display: none;
        }
    }

    .content_pages .tab-content {
        padding: 20px;
    }

    .content_pages ul {
        padding-left: 20px;
    }
}

@media(max-width:$mobileScreen) {
    .banner-item>div {
        padding: 40px;

        p {
            font-size: 1.2rem;
        }

        h2 {
            font-size: 4rem;
        }
    }

    .header__menu-list {
        display: none;
    }

    .header .header__logo {
        width: 200px;
    }

    .header .header__logo img {
        max-height: 65px;
    }

    .header {
        height: max-content;
    }

    .header__menu-icon {
        display: flex !important;
        margin: 0;
    }

    .header__menu-item-new {
        display: none;
    }

    .header__menu-icon-mobile {
        display: block !important;
        border-right: none !important;
        padding-right: 0 !important;
    }

    .header__menu-item svg {
        font-size: 1.7rem;
    }

    .header__menu-text {
        display: flex !important;
        position: fixed;
        flex-direction: column;
        width: 60%;
        height: 100vh;
        top: 0;
        right: -60%;
        background-image: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.5));
        padding: 30px 0 0 15px;
        transition: .5s all ease;

        li {
            margin-bottom: 15px;
        }

        &-active {
            right: 0 !important;
        }
    }

    .header__menu-item-rightIcon {
        display: block !important;

    }

    .header .header__menu-list-c2 {
        background: unset;
        position: unset;
        visibility: visible;
        opacity: unset;
        overflow: hidden;
        transition: .5s all ease;
        display: none;
        padding-bottom: 0;
    }

    .header__menu-item span {
        bottom: unset;
    }

    .header__menu-item:hover .header__menu-list-c2 {
        display: block !important;
    }

    .header__menu-list-c2 .header__menu-item {
        margin-bottom: 5px;

        &:last-child {
            margin-bottom: 0 !important;
        }
    }

    .home_clientsWords-content {
        .layout_container {
            width: 850%;
        }

        .logo-slider ul {
            animation: 55s slideContinuous infinite linear !important;
        }
    }

    .section3-img {
        background: #333 !important;

        img {
            opacity: .6;
        }
    }

    >div {
        display: flex !important;
    }

    .home .section3 .section3-img>div {
        display: flex !important;
    }

    .category .categori_border::after {
        content: unset;
    }

    .section3_detailMobile {
        display: flex !important;
    }

    .introduce .introduce__content-capacity ul {
        display: block;

        li {
            margin-bottom: 20px;
        }
    }

    .introduce .IntroduceCulture-content {
        display: block;
    }

    .sectionEsm>div {
        grid-template-columns: 100% !important;

        .sectionEsm-content {
            display: none !important;
        }
    }

    .home .home_clientsWords-content .logo-slider:hover ul {
        animation-play-state: unset !important;
    }

    .home .home_clientsWords-content .logo-slider {

        &::after,
        &::before {
            width: 0 !important;
        }
    }

}