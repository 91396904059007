 @import "../../_base";

.button_2{
    padding: 5px 10px !important;
    border:2px solid #fff;
    color: #fff;
    width: max-content;
    height: max-content;
    border-radius: 50px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: .8s all ease;
    position: relative;


    &::after{
        position:absolute;
        content: "";
    }



    &:hover{
        background-color:#fff;
    }
    &:hover p, &:hover  svg{
        color: $primarycolor;

    }

    &:hover span{
        width: 100% !important;
        opacity: 1;
    }
    > p{
        font-size: 1.4rem;
        color: #fff;
        margin-left: 10px;
        font-family: 'Montserrat',sans-serif;
        text-transform: uppercase;
        position: relative;
        z-index: 2;
        transition: .8s all ease;

    }
    svg{
        font-size: 2rem;
        color:#fff ;
        position: relative;
        z-index: 2;
        transition: .8s all ease;
    }

    span{
        position: absolute;
        left: 0;
        width: 0;
        height: 100%;
        background: #fff;
        transition: .8s all ease;
        border-radius: 50px;
        opacity: 0;
    }
}