
.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transition: .3s all ease-in-out;
    z-index: 100;
    height: 105px;
    // background-color: rgb(37, 33, 33);
    .header__menu-icon-mobile{
        display: none;
    }
    &-active{
        background: rgb(35, 35, 35);
        // background: $primarycolor;
        color: #fff; 
        opacity: .8;
    }

    .header__logo {
        width: 300px;
        margin: 10px 0 10px 0;
        img {
            max-height:$heightMenu;
        }
    }

    &__menu {
        display: flex;
        font-size: 2rem;
        flex: 1;
        justify-content: space-between;
        align-items: center;
        padding: unset !important;

        &-active {
            color: $primarycolor;
        }

        &-list {
            display: flex;
            position: .5a all ease;
        }

        &-top{
            float: right;
        }
       
        &-listtop{
            display: flex;
            margin:10px;
            li{
                border-left: 1px solid rgb(255 255 255 / 15%);
                border-right: 1px solid rgb(255 255 255 / 15%);
                font-size: 1.4rem;
            }
        }

        &-icon{
            direction: rtl;
            margin-top: 0;
        }

        &-item {
            padding:0 10px;
            font-weight: 600;
            color: #fff;
            cursor: pointer;
            transition: .2s all ease;
            position: relative;
            white-space: nowrap;

            &:hover {
                color: $primarycolor;
            }

            &:hover span {
                width: 90%;
            }

            &:hover .header__menu-list-c2 {
                visibility: visible;
                opacity: 1;
                top: 140%;
            }

            span {
                position: absolute;
                width: 0;
                height: 2px;
                left: 5px;
                bottom: -5px;
                background-color: $primarycolor;
                transition: .5s all ease;
            }

            p {
                font-size: 1.4rem;
                // text-transform: uppercase;

            }

            svg {
                font-size: 1.6rem;

            }
        }

        .header_icon-search {
            border-left: 1px solid rgb(255 255 255 / 15%);
            border-right: 1px solid rgb(255 255 255 / 15%);
        }

    }
    .header__menu-item-rightIcon{
        position: absolute;
        top: 10px;
        right: 10px;
        display: none;
        svg{
            font-size: 3rem;
            color:  $primarycolor;
        }
    }

    .header__menu-list-c2 {
        flex-direction: column;
        justify-content: center;
        position: absolute;
        padding: 10px;
        background: rgba(35, 35, 35, 1);
        border-radius: 5px;
        opacity: 0;
        visibility: hidden;
        top: 120%;
        transition: .5s all ease;

        li {
            padding: 5px;
        }

        p {
            font-size: 1.3rem;
            text-transform: unset;
        }

    }
}