.title_center {
    font-size: 2.4rem;
    color: rgb(77, 183, 72);
    margin: 30px 0 30px 0;
    position: relative;


    &::after {
        position: absolute;
        content: "";
        height: 4px;
        bottom: -8px;
        width: 45px;
        left: 0;
        border-radius: 2px;
        background: #ccc;
    }
}