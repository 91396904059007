.PScategory {
    font-weight: 400;
    position: relative;
    padding: 0 0 10px;
    margin: 0 0 20px;
    line-height: 1.4;
    margin: 40px 0;

    .listPScategory {
        margin: 0 0 30px;
        padding: 0 0 15px;
        border: 1px solid #e6e6e6;

        img {
            width: 100%;
            height: auto;
        }

        >p {
            font-size: 17px;

        }

        >h4 {
            font-size: 23px;
        }

        .nav {
            li {
                p {
                    font-size: 14px;
                }
            }
        }
    }

    .PScategory-list { 

        .PScategory-item{
            border-bottom: 1px solid $primarycolor;
            margin-top: 20px;
            padding: 0 20px 20px 20px;
            cursor: pointer;
        }

        .PScategory-itemlist{
            border: 1px solid $primarycolor;
            margin-top: 20px;
            padding: 0 20px 20px 20px;
            cursor: pointer;
            border-radius: 10px;
        }
        text-align: center;
        
        img {
            text-align: center;
            width: 200px;
            height: 200px;
            max-width: 100%;
        }
        h4{
            font-size: 1.7rem;
            font-weight: 600;
        }
        p{
            text-align: justify;
            @include line-clam(3)
        }
    }
}


.DetailPSCategory {
    font-size: 24px;
    font-weight: 400;
    position: relative;
    padding: 0 0 10px;
    margin: 0 0 20px;
    line-height: 1.4;

    .listDetailPScategory {
        margin: 0 0 30px;
        padding: 0 0 15px;
        border: 1px solid #e6e6e6;

        img {
            width: 100%;
            height: auto;
        }

        p {
            font-size: 17px;
        }
    }

}