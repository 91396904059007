
.footer {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #f3f3f3;
    // background: #d3f8e7;
    // .layout_container{
    //     >span{
    //         height: 2px;
    //         width: 80%;
    //         display: block;
    //         background: $primarycolor;
    //         margin: auto;
    //     }
    // }

    &_icons {
        display: flex;
        position: relative;

        &-left {
            flex: 1;
            justify-content: center;
            display: flex;

            p {
                padding: 10px;
                // background: #fff;
                color: $primarycolor;
                border-radius: 50%;
                border: 1px solid $primarycolor;
                margin-right: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: .5s all ease;
                cursor: pointer;

                &:hover {
                    background: $primarycolor;
                    color: #fff;
                }

                svg {
                    font-size: 2rem;
                }
            }
        }
    }

    &_contract {
        text-align: center;
        height: 100%;
        align-items: center ;

        .icon-contact {
            font-size: 15px;
            margin: 0 10px 5px 0;
            width: 100%;
        }

        div {

            p {
                &:first-child {
                    font-weight: 600;
                    margin-bottom: 10px;
                    text-transform: uppercase;
                }

                font-size: 1.4rem;
                color: $primarycolor;
            }

            span {
                margin: 10px auto;
                background: $primarycolor;
                margin-top: 20px;
                height: 1px;
                width: 300px;
                display: block;
            }

            img{
                width: 100%;
            }
        }
    }
    &_icons-Partner{
        display: flex;
        justify-content: center;
        p{
            padding: 10px;
                // background: #fff;
                color: $primarycolor;
                border-radius: 50%;
                border: 1px solid $primarycolor;
                margin-right: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: .5s all ease;
                cursor: pointer;
                font-size: 2.8rem;
        }
    }
    &_qr{
        display: flex;
        justify-content: center;
        img{
            padding: 10px;
            max-width: 100%;
        }
    }

    &_top{
        padding-bottom: 15px;
        .row{
            >div{
                margin-top: 30px;
            }
        }
    }
    &_bottom{
        background: $primarycolor;
        width: 100%;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        ul{
            display: flex;
            justify-content: center;
            flex: 1;

            li{
                color: #fff;
                margin-right: 20px;
                font-size: 1.5rem;
                cursor: pointer;
                transition: .5s all ease;

                &:hover{
                    color: $primarycolorHover;
                }
            }
        }
        p{
            color: #fff;
            font-size: 1.2rem;
        }
    }
}

