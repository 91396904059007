.typicalProject_container {
    padding-bottom: 60px;
    cursor: pointer;

    .TypicalProjectSlider {

        .TypicalProjectSlider-content {
            position: relative;

            >img {
                width: 100%;
                height: 100%;

                &:nth-child(3) {
                    margin-top: 50px;
                }
            }

            p {
                font-size: 2.6rem;
                background: rgba(51, 51, 51, .48);
                position: absolute;
                height: 100px;
                width: 100%;
                bottom: 6px;
                padding: 1rem;
                color: #fff;
            }

            div {
                width: 100%;
                position: absolute;
                bottom: 10px;
                z-index: 100;
                display: inline;
                height: auto;
                left: 0;
                display: flex;
                justify-content: center;

               > p {
                    color: #fff;
                    background-color: $primarycolor;
                    border:1px solid $primarycolor;
                    font-size: 1.3rem;
                    width: max-content;
                    height: max-content;
                    border-radius: 5px;
                    padding: 5px 10px;
                    transition: .5s all ease;

                    &:hover{
                        color: $primarycolor;
                        background: #fff;
                    }

                }
            }

        }
    }
}
