.top_banner {
    position: relative;
    &-img {
        max-height: 420px;
        height: 300px;
        background-blend-mode: multiply;
    }

    &-title{
        position: absolute;
        bottom: 40px;
        left: 0;
        height: max-content;
        width: 100%;

        h4{
            font-size: 3.5rem;
            font-weight: 500; 
            color:  $primarycolor;
            letter-spacing: 5px;
            text-transform: uppercase;
        }
        h4{
            font-size: 3.5rem;
            font-weight: 500; 
            color:  $primarycolor;
            letter-spacing: 5px;
            text-transform: uppercase;
        }
        p{
            font-size: 1.5rem;
            // color: #fff;
            margin-top: 20px;
        }
    }

}