@import "../../_base";

.contact_container{
    position: fixed;
    right: 0;
    z-index: 10;
    top: 110px;
    
    ul{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;

        li{
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            transition: .3s all ease;
            right: 0 ;
            position: relative;
            right: - 70%;
            width: 130px;
            padding: 3px 10px;
            background: #fff;
            margin-top: 10px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            box-shadow: 0 0 10px rgba(0,0,0,0.2);


            &:hover{
                background: $primarycolor;
                color: #fff;
                right: 0;
                opacity: 1;
            }
            &:hover svg{
                color: #fff;
            }
           
            

           
            a{
                display: flex;
                align-items: center;
                
                svg{
                    font-size: 1.6rem;
                    font-weight: 600;
                    color: $primarycolor;
                }
                p{
                    font-size: 1.4rem;
                    color:#fff;
                    flex: 1;
                    text-align: left;
                    margin-left: 10px;
                }
            }
        }
    }
}