.customers_container{
    padding-bottom: 60px;
    // margin-top: calc($heightMenu + 20px);
    .layout_container{
        margin-top: 40px !important;
    }
    ul{
        
        li{
            cursor: pointer;
            border: 2px solid #ccc;
            overflow: hidden;
            max-height: 120px;
            border-radius: 10px;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
            transition: .5s all ease;
            margin-top: 20px;
            height: 110px;
           
            &:hover{
                border-color: $primarycolor;
            }

            a{
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px;
                
            }
            img{
                width: 100%;
                height: 100%;
                transition: .5s all ease;

                &:hover{
                    transform: scale(1.2);
                }
            }
        }
    }
}