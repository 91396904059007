
.introduce {
    .top-banner {
        div {
            max-height: 420px;
            height: 300px;
            background-color: #d2d2d2;
            background-blend-mode: multiply;
        }


    }

    h3 {
        font-size: 2.4rem;
        color: $primarycolor;
        margin: 30px 0 20px 0;
        position: relative;

        &::after {
            position: absolute;
            content: "";
            height: 4px;
            bottom: -8px;
            width: 45px;
            left: 0;
            border-radius: 2px;
            background: #ccc;
        }
    }

    .introduce_menu {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 50px 0;
        flex-wrap: wrap;
        .introduce_menu-active {
            background: $primarycolor;
            color: #fff;
        }

        li {
            margin-top: 10px;
            padding: 5px 14px;
            border: 1px solid #ccc;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 5px;
            border-radius: 5px;
            cursor: pointer;
            transition: .5s all ease;

            p {
                font-size: 1.4rem;
            }

            &:hover {
                background: $primarycolor;
                color: #fff;
                border: 1px solid #fff;
            }
        }
    }

    &__content {
        p {
            font-size: 1.6rem;
            line-height: 35px;
            text-align: justify;
        }
        
        h5 {
            margin-top: 20px;
            font-size: 1.7rem;
            font-weight: bold;
            font-family: "Manrope", sans-serif;
            letter-spacing: -1;
        }

        &-img {
            width: 100%;
            display: flex;
            justify-content: center;
        }

        margin-bottom: 50px;
    }

    .introduce__content-capacity {
        margin-top: 40px;

        ul {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 50px;
        }

        li {
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            padding: 5px 30px 30px 30px;
            border-radius: 10px;
        }


        &-img {
            width: max-content;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: auto;
            background: $primarycolor;
            border-radius: 50%;
            padding: 15px;
            margin-top: 20px;

            img {
                width: 50px;
                height: 50px;
            }
        }


        &-text {
            p {
                font-size: 1.6rem;
                text-align: justify;
            }
        }
    }

    .IntroduceCulture-content{
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        div{
            flex: 1;
            
            &:last-child{
                text-align: center;
            }
            img{
                width:80%;
            }
        }
        p{
            text-align: justify;
        }        
        

    }
}
.IntroduceCulture{
    span{
        margin: 10px auto;
            background: $primarycolor;
            margin-top: 20px;
            margin-bottom: 20px;
            height: 2px;
            display: block;
    }
}