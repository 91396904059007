
.banner-item{
    background-size:cover ;
    background-position:center ;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    >div{
        color: #fff;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 0 400px;
       p{
        font-size: 1.4rem;
        text-transform: uppercase;
        color: #ededed;
        letter-spacing: 5px;
       }
       h2{
            font-size: 8rem;
            font-weight: 600;
            margin: 20px 0;
            text-align: center;

       }

       div{
            padding: 5px 15px;
            font-size: 1.3rem;
            font-weight: 600;
            border: 2px solid #fff;
            width: max-content;
            text-transform: uppercase;
            cursor: pointer;
            border-radius: 5px;
            transition: .5s all ease;

            &:hover{
                background: #fff;
                color: $primarycolor;
                border-color: $primarycolor;
                transform: scale(1.1);
            }
       }
    }

}