.scrollTop{
    border: 1px solid $primarycolor;
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    height: max-content;
    position: fixed;
    bottom: 80px;
    right: 50px;
    z-index: 10;
    border-radius: 50%;
    padding: 10px;
    background: $primarycolor;
    cursor: pointer;
    opacity: 0;
    transition: .5s all ease;
    visibility: hidden;

    
    &:hover{
        opacity: 1;
        transform: scale(1.1);
    }
    &-active{
        bottom: 50px;
        opacity: .4;
        visibility:visible;
    }
    &:hover span {
        top: -50px;
        visibility: visible;
        opacity: .7;
    }
    div{
        position: relative;

        svg{
            font-size: 2rem;
            color: #fff;
        }
        span{
            position: absolute;
            font-size: 1.2rem;
            background: rgb(0, 0, 0.2);
            color: #fff;
            opacity: .8 ;
            white-space: nowrap;
            padding: 8px;
            border-radius: 100px;
            left: -29px;
            top: -70px;
            visibility: hidden;
            opacity: 0;
        }
    }
}