.home {
    .category_leader {
        display: block;

        >li {
            list-style: disc !important;
            box-shadow: none !important;
            border: none !important;
            width: 100% !important;
            margin: 0 !important;
            padding: 0 !important;
        }
    }

    .leader-summary {
        @include line-clam(5)
    }

    &__title {
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        text-transform: uppercase;
        letter-spacing: 5px;
        margin-bottom: 50px;

        p {
            font-size: 1.4rem;
            color: #ccc;
        }

        h3 {
            font-size: 2rem;
            font-weight: 600;
            margin: 5px 0 15px 0;
        }

        span {
            background: $primarycolor;
            height: 2px;
            width: 120px;
        }
    }

    .section-fullscreen {
        width: 100%;
        position: relative;

        .section {
            width: 100%;
            display: flex;
            align-items: center;

            div {
                flex: 1;
                height: 30px;
                background-color: red;
            }
        }

    }

    .section1 {
        align-items: center;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        div {
            flex: 1;
            font-size: 5rem;
            padding: 0 20px;


            p:first-child {
                font-size: 2.6rem;
                color: $primarycolor;
                margin-bottom: 10px;
                font-weight: 600;

            }

            h4 {
                font-size: 3.2rem;
                font-weight: 600;
            }

            p:last-child {
                font-size: 1.6rem;
                color: #6f6f6f;
                margin-top: 10px;
                font-weight: unset !important;
                text-align: justify;
            }


        }

        .section1-model {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            margin-top: 20px;
            column-gap: 20px;

            div {
                padding: 0;

                img {
                    height: 40px;
                }

                p {
                    font-size: 1.6rem;
                    margin-bottom: 10px;
                    font-weight: 600;
                    text-align: justify;
                }
            }
        }

    }

    .section-img {
        background-image: url("../../images//homepage-option1.jpg");
        height: 650px;
        background-size: cover;
        overflow: hidden;
        background-position: center;
        background-repeat: no-repeat;
    }

    .p-bt-100 {
        padding: 2f0px 0;
    }

    .section2 {

        &-title {
            padding: 0 200px;
        }

        div {
            h3 {
                font-size: 2.4rem;
                font-weight: 600;
                text-align: center;
            }

            p {
                margin: 20px 0 10px 0;
                text-align: center;
                font-size: 1.6rem;
            }
        }

        &-content {
            margin-top: 60px;

            &-item {
                padding: 50px 20px;
                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                display: flex;
                flex-direction: column;
                align-items: center;
                border-radius: 10px;
                height: 260px;
                margin-bottom: 10px;

                div {
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: $primarycolor;

                    img {
                        height: 30px;
                    }
                }

                p:nth-child(2) {
                    color: $primarycolor;
                    margin-bottom: 10px;
                    font-size: 1.6rem;
                    font-weight: 600;
                }

                p:last-child {
                    font-size: 1.4rem;
                    margin-top: 0;
                }


            }
        }
    }

    .section3 {

        display: grid;
        grid-template-columns: repeat(1fr);

        >div {
            flex: 50%;
            display: grid;
            grid-template-columns: repeat(2, 1fr);


            img {
                max-width: 100%;
                border-radius: 10px;
            }

            >div {
                background: $primarycolor;
                padding: 0 56px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                border-radius: 10px;
                margin-left: 10px;

                >p {
                    font-size: 1.6rem;
                    color: #fff;

                    &:first-child {
                        font-weight: 600;
                        margin-bottom: 20px;
                    }

                    &:last-child {
                        font-weight: 600;
                        margin-bottom: 10px;
                        font-size: 1.4rem;
                    }
                }

                h3 {
                    font-weight: 600;
                    color: #fff;
                }
            }

        }

        .section3-img {
            padding: 0 !important;
            position: relative;
            display: block;

            >div {
                position: absolute;
                bottom: 20px;
                display: none;
                justify-content: center;
                width: 100%;

                // .button_2 {
                //     border: 1px solid $primarycolor;

                //     svg,p {
                //         color: $primarycolor;

                //     }
                // }
            }
        }
    }

    .section4 {

        >h3 {
            text-align: center;
            width: 100%;
            color: $primarycolor;
            font-weight: 600;
            margin-bottom: 50px;
            font-size: 2rem;
            text-transform: uppercase;
        }

        &-grid {
            padding: 15px;

            li {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 20px 0;
                height: 150px;
                margin-bottom: 25px;
            }

            &-card {
                cursor: pointer;
                overflow: hidden;
                max-height: 293px;
                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                border-radius: 10px;

                &:hover img {
                    transform: scale(1.1);
                }

                &:hover div {
                    top: -90px
                }

                >div {
                    margin-top: 30px;
                    position: relative;
                    top: 0;
                    transition: .2s all ease;

                    p {
                        text-transform: uppercase;
                        font-size: 1.4rem;
                        text-align: center;

                        &:first-child {
                            font-weight: 600;
                        }
                    }
                }

                img {
                    width: 60%;
                    position: relative;
                    top: 0;
                    transition: .2s all ease;
                }
            }
        }
    }

    .section6 {
        display: flex;

        >div,
        img {
            flex: 1;
        }


        >div {
            padding: 60px;
            background-color: #f3f3f3;

            h3 {
                font-size: 3rem;
                font-weight: 600;
                margin-bottom: 20px;
                color: $primarycolor;
            }

            ul {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 20px;

                li {

                    display: grid;
                    grid-template-columns: 40px auto;
                    gap: 10px;

                    img {
                        width: 30px;
                        height: 30px;
                    }

                    div {
                        p:first-child {
                            font-size: 1.6rem;
                            color: #333;
                            font-weight: 600;
                            margin-bottom: 10px;
                        }

                        p:last-child {
                            font-size: 1.4rem;
                            color: #9e9e9e;


                        }
                    }
                }
            }
        }
    }

    &_ourservices {
        padding: 20px 0;

        &-content {
            width: 100%;

            >ul {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                text-align: center;

                li {
                    div {
                        width: 407px;
                        height: 253px;
                        max-width: 407px;
                        max-height: 253px;
                        overflow: hidden;
                        border-radius: 10px;
                        background: #333;
                        cursor: pointer;

                        img {
                            transition: .5s all ease;
                            width: 100%;
                            height: 100%;
                            border-radius: 10px;

                            &:hover {
                                transform: scale(1.3);
                                opacity: .4;
                            }
                        }
                    }

                    p {
                        font-size: 1.4rem;
                        margin-top: 5px;
                        font-weight: 600;

                        &:last-child {
                            color: #ccc;
                            font-weight: normal;
                        }
                    }
                }
            }
        }

    }


    &_blog {
        // background: #f2f2f2;
        padding: 50px 0;

        &-content {

            ul {

                li {
                    background: #fff;
                    padding: 20px;
                    border-radius: 10px;
                    cursor: pointer;

                }
            }


            &-img {
                background-color: #333;
                width: 100%;
                overflow: hidden;
                // border-radius: 10px;

                img {
                    width: 100%;
                    transition: .5s all ease;

                    &:hover {
                        transform: scale(1.4);
                        opacity: .5;
                    }
                }
            }

            &-time {
                margin: 20px 0;

                span {
                    font-size: 1.1rem;
                    color: #b2b2b2;

                    &:nth-child(2) {
                        margin: 0 5px;
                        pointer-events: none;
                    }

                    &:hover {
                        color: $primarycolor;
                    }

                }
            }

            &-title {
                font-size: 1.6rem;
                padding-bottom: 20px;
                border-bottom: 1px solid $primarycolor;

                &:hover {
                    color: $primarycolor;
                }

            }

            &-description {
                color: #b2b2b2;
                font-size: 1.4rem;
                margin-top: 20px;
                @include line-clam(3);
            }
        }
    }

    



    .home_clientsWords-content .logo-slider {
        background-image: url("../../images/banner/background_sliderLogo.jpg");
        height: 100%;
        padding: 60px 0;
        overflow: hidden;
        white-space: nowrap;
        position: relative;

        &:hover ul {
            animation-play-state: paused;
        }

        &::before,
        &::after {
            position: absolute;
            top: 0;
            width: 250px;
            height: 100%;
            content: "";
            z-index: 10;
        }

        &::before {
            left: 0;
            background: linear-gradient(to left, rgba(255, 255, 255, 0), $primarycolor);
        }

        &::after {
            right: 0;
            background: linear-gradient(to right, rgba(255, 255, 255, 0), $primarycolor);
        }

        ul {
            display: flex;
            animation: 15s slideContinuous infinite linear;

            li {
                text-align: center;
                cursor: pointer;

                img {
                    height: 80px;
                    margin: 0 40px;
                }

                p {
                    font-size: 1.2rem;
                    margin-top: 20px;
                    font-weight: 700;
                    color: $primarycolor;
                    text-transform: uppercase;


                }
            }
        }


    }

    
    @keyframes slideContinuous {
        from {
            transform: translateX(0);
        }

        to {
            transform: translateX(-100%);
        }
    }


    .sectionEsm{
        > div {
            display: grid;
            grid-template-columns: 50% 50%;
            column-gap: 10px;

            img{
                width: 100%;
                border-radius: 10px;
            }

            .sectionEsm-content{
                background: $primarycolor;
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 0 30px;
                color: #fff;
                border-radius: 10px;
                
                .sectionEsm-content-title{
                    font-weight: 800;
                }
            }
        }
    }

}