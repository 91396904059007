@import "./__base";
@import "./components/header/header.scss";
@import "././components//button_2/button.scss";
@import "././components/footer/footer.scss";
@import "././components/loading/loading.scss";
@import "././components/scrollTop/scrollTop.scss";
@import "././components/slider/slider.scss";
@import "././pages/home/home.scss";
@import "././pages/introduce/introduce.scss";
@import "././components/topBanner/topBanner.scss";
@import "././components/title/title_center.scss";
@import "././pages/customers/customers.scss";
@import "././pages/typicalProject/typicalProject.scss";
@import "./pages//News/news.scss";
@import "./pages/productSolution/productSolution.scss";
@import "./pages/contact/contact.scss";
@import "./pages/recruitment/recruiment.scss";
@import "./components/notfound/pagenotfound.scss";
// @import "./pages/leaderShip/leaders.scss";

.App {
  overflow: hidden;

    .layout_container {
        width: 90%;
        margin: auto;
        // display: flex;
        // justify-content: space-between;
    }

    .swiper {
        width: 100%;
        height: 100%;
      }
      
      .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;
      
        /* Center slide text vertically */
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        
      }
}
