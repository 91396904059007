.contact{
    padding-top: 10px;
    .contact-title{
        font-size: 30px;
        color: $primarycolor;
        font-weight: 600;
    }
    .contact-icon{
        font-size: 30px;
        padding-right: 10px;
    }
    span{
        font-size: 16px;
        font-weight: 300;
        line-height: 1.5;
        color: #333;
        margin: 0 0 10px;
    }
}

.contact-map{
    padding-top: 10px;
    padding-bottom: 10px;
}