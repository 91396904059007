
.card {
    padding: 2rem;
    // border-radius: 10px;
    margin-bottom: 1rem;
    
    td{
        font-size: 1.8rem;
    }
}

.recruimentContent li {
    }